import { createLogger } from "@kha/logging";
import Rollbar from "rollbar";

import { logging, rollbarToken, stack, version } from "./configuration";
import { ApplicationError } from "./core/error";
import { settings } from "./core/settings";
import { platform } from "./support/platform";

const { hostname, ip } =
  (typeof window !== "undefined" ? window.native : undefined) ?? {};

const context = {
  platform,
  hostname,
  ip,
  user: ""
};

type Context = typeof context;

let rollbar: Rollbar | undefined;

export const updateContext = (_: Partial<Context>) => {
  Object.assign(context, _);
  rollbar?.configure({
    payload: {
      person: {
        id: context.hostname ?? context.user
      }
    }
  });
};

export const logger = createLogger({
  enable: logging && !settings.disableTelemetry,
  env: stack,
  app: "command",
  version,
  context
});

export const initializeLogging = () => {
  rollbar = Rollbar.init({
    enabled: logging,
    accessToken: rollbarToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: stack,
    codeVersion: version
  });

  const onError = (error: Error) => {
    if (error instanceof ApplicationError)
      logger.warn("Application error", error);
    else logger.error("Error", error);
  };
  self.addEventListener("error", ({ error }) => onError(error));
  self.addEventListener("unhandledrejection", ({ reason: error }) =>
    onError(error)
  );
};
